*{
    font-size: 13px;
}

.button{
    background-color: black;
    color: white;
    border: 0;
    border-radius: 0%;
    padding: 8px 35px;
}

.button:hover{
    background-color: rgb(34, 34, 34);
}

li.nav-item {
    margin: 0 25px;
    color: white;
}

li.nav-item :hover {
    /* margin: 0 25px; */
    color: #0294FE;
}
a.nav-link{
    color: white;
}
.navbar-nav{
    margin-left: 15px;
}